import React from 'react'
import CaretSvg from '../images/imported/icons/rightCaretPointy.svg'
import styled from 'styled-components'

const StyledCaret = styled(CaretSvg)`
  fill: ${props => props.color || '#333'};
  transition: transform 0.3s ease;

  &.up {
    transform: rotateZ(-90deg);
  }

  &.down {
    transform: rotateZ(90deg);
  }

  &.left {
    transform: rotateZ(180deg);
  }
`

const Caret = ({ direction, ...props }) => {
  return <StyledCaret className={direction} {...props} />
}

export default Caret
