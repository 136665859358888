import React from 'react'
import styled from 'styled-components'
import { FormattedMessage as FM } from 'react-intl'

import Layout from '../components/layout'
import { OneColumnLayout } from '../components/display/oneColumn'
import { Box, Flex } from '@makerdao/ui-components-core'
import Link from '../components/link'
import getUrls from '../urls'
import Caret from '../components/caret'

import BackgroundImg from '../images/imported/governance/hero-background.svg'
import { getGenericPageTitle, getMessage, messages } from '../i18n'

const Button = styled(Link)`
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 40px;
  height: 48px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.015em;

  transition: background-color 0.1s ease 0s;
  cursor: pointer;

  background-color: ${props => props.theme.colors.darkPurple};
  color: #f9fafc;
  font-weight: 500;
  text-decoration: none;
  :hover {
    background-color: #50445e;
    color: #f9fafc;
  }
`

const HeroStyle = styled(Box)`
  overflow: hidden;
  border-radius: 10px;
  padding: 84px 48px 65px;
  position: relative;

  .content {
    max-width: 710px;
  }

  h1 {
    font-weight: bold;
    font-size: 48px;
    line-height: 44px;
    letter-spacing: 0.03em;
    margin-bottom: 16px;
    color: #273444;
  }

  p {
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    letter-spacing: 0.015em;
  }

  ${Button} {
    margin-top: 23px;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f7f7f7
      radial-gradient(216.85% 126.36% at 0% 0%, #bafee7 0%, #e7fcfa 100%);
    z-index: -1;
    svg {
      position: absolute;
      left: 688px;
      top: 0;

      @media (max-width: 829px) {
        left: unset;
        right: -311px;
      }
    }
  }
`

const LINK_COLOR = '#139D8D'

const StyledCaret = styled(Caret)`
  fill: ${LINK_COLOR};
`

const Column = styled(Box)`
  max-width: 288px;
  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: ${props => props.theme.colors.darkPurple};
    margin-bottom: 9px;
  }

  p {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #48495f;
    min-height: 96px;
    margin-bottom: 12px;
  }

  a {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: ${LINK_COLOR};
    text-decoration: none;
  }

  ${StyledCaret} {
    margin-left: 7px;
    transition: all 0.2s ease;
    transform: scale(0.9);
  }

  a:hover {
    color: ${LINK_COLOR};
    opacity: 0.8;
    ${StyledCaret} {
      transform: scale(0.9) translateX(2px);
    }
  }
`

const Columns = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  .column:not(:first-child) {
    margin-top: 44px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    align-items: inherit;
    flex-direction: row;

    .column:not(:first-child) {
      margin-top: 0;
      margin-left: 25px;
    }
  }
`

const Governance = ({ pageContext: { locale } }) => {
  const urls = getUrls(locale)

  return (
    <Layout
      locale={locale}
      meta={{
        title:
          messages[locale]['governance.meta.title'] ||
          getGenericPageTitle('Governance', locale),
        description: getMessage('governance.meta.description', locale),
      }}
    >
      <OneColumnLayout maxWidth="1140px" px="25px" minHeight="0px" mt="70px">
        <HeroStyle>
          <div className="content">
            <h1>
              <FM id="governance.hero-title" />
            </h1>
            <p>
              <FM id="governance.hero-body" />
            </p>
            <Button to={urls('Vote')}>
              <FM id="governance.hero-btn" />
            </Button>
          </div>
          <div className="background">
            <BackgroundImg />
          </div>
        </HeroStyle>
        <Columns mt="128px" mb="77px">
          {[
            urls('Forum'),
            'https://community-development.makerdao.com/onboarding/voter-onboarding',
            'https://community-development.makerdao.com/governance/governance',
          ].map((url, index) => {
            const num = index + 1
            return (
              <Column className="column">
                <h3>
                  <FM id={`governance.column${num}-title`} />
                </h3>
                <p>
                  <FM id={`governance.column${num}-body`} />
                </p>
                <Link to={url}>
                  <FM id={`governance.column${num}-link-text`} />
                  <StyledCaret direction="right" />
                </Link>
              </Column>
            )
          })}
        </Columns>
      </OneColumnLayout>
    </Layout>
  )
}

export default Governance
